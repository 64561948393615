export const calculateCost = ({ nfd, myKitties = [], myLegendaryKitties = [], purchaseCount }) => {
  const originalCost = parseInt(process.env.NEXT_PUBLIC_VENDING_MACHINE_COST) * (purchaseCount + 1);
  let discountedCost = originalCost;

  // handle purchaseCount logic
  if (purchaseCount === 0 && myLegendaryKitties.length > 0) {
    discountedCost = 0;
  }
  if (purchaseCount > 1) {
    discountedCost = discountedCost * (purchaseCount + 1);
  }
  if (purchaseCount > 2) {
    discountedCost = 99999999;
  }

  // handle discount logic
  let discount = 0;
  if (!!nfd) {
    discount = 0.2;
  }
  if (myKitties.length > 19) {
    discount = discount + 0.3;
  }
  if (discount > 0) {
    discountedCost = discountedCost * (1 - discount);
  }

  return { originalCost, discountedCost: Math.round(discountedCost) };
};

export const MAXIMUM_WINNERS = 25; // was 10
export const VM_TOKEN_AMOUNT_NEEDED = 6;

export const calculateOddsOfWinning = (vmTokenBalance) => {
  if (vmTokenBalance >= VM_TOKEN_AMOUNT_NEEDED) {
    return 0.9; // was 0.65
  }
  return MAXIMUM_WINNERS / 100;
};

export const calculateParticipants = (vmTokenBalance) => {
  const winnerPercentage = calculateOddsOfWinning(vmTokenBalance);
  // Calculate the total number of participants based on the percentage of winners
  const totalParticipants = MAXIMUM_WINNERS / winnerPercentage;
  // Calculate the number of losers
  const losers = totalParticipants - MAXIMUM_WINNERS;
  return { WINNERS: MAXIMUM_WINNERS, losers };
};
